<template>
  <div class="card  shadow-none">
    <div class="card-header d-flex align-items-center justify-content-between">
      <h6>
        <span v-text="$t('dashboard.order.packageInformation')"> </span>
      </h6>
      <button class="btn btn-sm btn-info " @click=" fnPrintLabel(shippingInformation.label) "
        v-if=" shippingInformation.label && orderInformation.shipment_status != 'delivered' ">
        <span v-text=" $t('general.button.printLabel') "></span>
      </button>
    </div>
    <div class="card-body animated fadeIn" v-if="newTrackingType == null">
      <b-tabs class="card ecart-tabs m-0 shadow-none ligth" justified>
        <b-tab :title="$t('tables.package')" active>

          <div class="row">
            <!-- <div class="col-12 text-center">
              <img  :class=" 'img-' + shippingInformation.carrier " class="img-contain-50 my-4" alt="">
            </div> -->
            <div class="col-12 col-lg-6" v-if="shippingInformation.tracking_number">
              <div class="row justify-content-center ">
                <div class="col-auto text-center">
                  <p>
                    <strong class="f-w-600" v-text=" $t('general.form.status')  "></strong>
                  </p>
                  <p class="f-w-500">
                    <span v-text=" $t('general.readyToShip') " class="text-info"
                      v-if="orderInformation.shipment_status == 'ready to ship' "> </span>
                    <span v-text=" $t('general.delivered') " class="text-success"
                      v-if="orderInformation.shipment_status == 'delivered' "> </span>
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-6" v-if="shippingInformation.tracking_number">
              <div class="row justify-content-center">
                <div class="col-auto text-center">
                  <p class="f-w-600" v-text=" $t('general.form.label')"></p>
                  <p class="f-w-500">
                    <a target="_blank" :href=" 'https://envia.com/rastreo?label=' + shippingInformation.tracking_number" v-text="shippingInformation.tracking_number"></a>
                    <!-- <span v-text=" shippingInformation.tracking_number ">
                    </span> -->
                  </p>
                </div>
              </div>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="row justify-content-between ">
                <div class="col-auto">
                  <p>
                    <span v-text=" $t('general.form.carrier') + ': ' "></span>
                  </p>
                </div>
                <div class="col-auto">
                  <p>
                    <span class="text-capitalize text-info" v-text=" shippingInformation.carrier "> </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <p>
                <span v-text=" $t('tables.service') + ': ' "></span>
                <span
                  v-text=" $te('general.carriers.services.' + shippingInformation.service) ? $t('general.carriers.services.' + shippingInformation.service) : shippingInformation.service  "></span>
              </p>
            </div>
          </div>

          <div class="row">

            <div class="col-12 col-lg-6">
              <div class="">
                <div class="row">
                  <div class="col-12">
                    <div class="row justify-content-between">
                      <div class="col-auto col-sm-7">
                        <p class="m-0"> <span v-text=" $t('tables.type')+ ':' "></span>
                        </p>
                      </div>
                      <div class="col-auto col sm-5">
                        <p class="m-0 text-uppercase">
                          <span v-text=" shippingInformation.packages[0].type "></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row justify-content-between">
                      <div class="col-auto col-sm-7">
                        <p class="m-0"> <span v-text=" $t('tables.amount') + ':' "></span>
                        </p>
                      </div>
                      <div class="col-auto col sm-5">
                        <p class="m-0"> <span v-text=" shippingInformation.packages[0].amount "></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 ">
                    <div class="row justify-content-between">
                      <div class="col-auto col-sm-7">
                        <p class="m-0"> <span v-text=" $t('tables.weight') + ':' "></span>
                        </p>
                      </div>
                      <div class="col-auto col sm-5">
                        <p class="m-0">
                          <span
                            v-text=" shippingInformation.packages[0].weight + ' ' +  shippingInformation.packages[0].weightUnit "></span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-6">
              <div class="">
                <div class="row justify-content-between">
                  <div class="col-auto">
                    <p class="m-0"> <span v-text=" $t('tables.length')+ ':' "></span> </p>
                  </div>
                  <div class="col-auto">
                    <p class="m-0">
                      <span
                        v-text=" shippingInformation.packages[0].dimensions.length + ' ' + shippingInformation.packages[0].lengthUnit "></span>
                    </p>
                  </div>
                </div>
                <div class="row justify-content-between">
                  <div class="col-auto">
                    <p class="m-0"> <span v-text="  $t('tables.width')+ ':' "></span> </p>
                  </div>
                  <div class="col-auto">
                    <p class="m-0">
                      <span
                        v-text=" shippingInformation.packages[0].dimensions.width + ' ' + shippingInformation.packages[0].lengthUnit "></span>
                    </p>
                  </div>
                </div>
                <div class="row justify-content-between">
                  <div class="col-auto">
                    <p class="m-0"> <span v-text="  $t('tables.height')+ ':' "></span> </p>
                  </div>
                  <div class="col-auto">
                    <p class="m-0">
                      <span
                        v-text=" shippingInformation.packages[0].dimensions.height + ' ' + shippingInformation.packages[0].lengthUnit "></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('general.tracking')" v-if=" orderInformation.shipment_status == 'ready to ship' ">
          <TrackingOrder class="animated fadeIn" v-if="shippingInformation.tracking_number != null" />
        </b-tab>
      </b-tabs>

    </div>
    <div class="card-body " v-else>
      <OrderOwnShipping class="animated fadeIn" />
    </div>

    <div class="card-footer"
      v-if="newTrackingType == null && orderInformation.shipment_status != 'ready to ship' && orderInformation.shipment_status != 'delivered' && orderInformation.order_status != 'in_review' ">
      <p class="m-0" v-if="shippingInformation.tracking_number == null">
        <span v-text=" $t('components.trackingNumber.messageTracking') + ' ' ">
        </span>
        <button class=" btn btn-link p-0 " v-text=" $t('linkMessages.here') + '.' "
          @click=" setNewTrackingType('own') ">
        </button>
      </p>
    </div>
  </div>
</template>

<script>
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";
  import TrackingOrder from './order-tracking';
  import OrderOwnShipping from './order-own-shipping';

  export default {
    components: {
      TrackingOrder,
      OrderOwnShipping,
    },
    computed: {
      ...mapState('order', ['orderInformation', 'shippingInformation', 'carriersList', 'newTrackingType']),
      ...mapState(['device']),

    },
    methods: {
      ...mapMutations('order', ['setNewTrackingType']),
      fnGetCarrierImage(carrierName) {
        let tempCarrier = this.carriersList.find(carrier => carrier.name == carrierName);
        if (tempCarrier) {
          return tempCarrier.image;
        } else {
          return null;
        }
      },
      fnPrintLabel(urlLabel) {
        if (this.device) {
          DownloadFile.postMessage(JSON.stringify({
            url: urlLabel,
            fileName: this.orderInformation.number + '-Label.pdf',
          }));
          // PDFLink.postMessage(JSON.stringify({
          //   url: urlLabel,
          // }));
        } else {
          window.open(urlLabel, '_blank');
        }
      }

    }
  }
</script>

<style>

</style>